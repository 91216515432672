// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery-ui")
require("lazyload")

// IMPORT BARBA
import barba from '@barba/core';
import { gsap } from "gsap";
// import barbaCss from '@barba/css';

// IMPORT OWL CAROUSEL
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

// IMPORT TYPEMATE
import TypeMate from 'typemate';


document.addEventListener("turbolinks:load", () => {

  //barba page transitions
  // barba.use(barbaCss)

  barba.init({
    debug: true,
    timeout: 10000,
    // preventRunning: true,

  	transitions: [



      {
        name: 'slide',

        leave(data) {

          console.log('leave')

          return new Promise(resolve => {
            const timeline = gsap.timeline({
              onComplete() {
                resolve()
              }
            })

            timeline
              .fromTo(data.current.container, {x: "0vw"}, {x: "100vw", duration: 0.6, ease: "power3"}, 0)
          })

        },

        enter(data) {

          console.log('enter')

          // reset page positioning
  				window.scrollTo({
  					top: 0,
  					behavior: "auto"
  				});

          // Get rid of type orphans
          typeMate()

          return new Promise(resolve => {
            data.current.container.remove();
            const timeline = gsap.timeline({
              onComplete() {
                resolve()
              }
            })

            timeline
              .fromTo(data.next.container, {x: "-100vw"}, {x: "0vw", duration: 0.6, ease: "power3"}, 0)
          })

        },

        beforeEnter() {},

        afterEnter() {},

      },






















  		// {
  		// 	name: "fade",
      //
      //   leave() {
      //     console.log('out')
      //   },
      //
      //   enter() {
      //     console.log('in')
      //   },
      //
  		// 	beforeEnter() {
      //
  		// 		// reset page positioning
  		// 		window.scrollTo({
  		// 			top: 0,
  		// 			behavior: "auto"
  		// 		});
      //
      //     // Get rid of type orphans
      //     typeMate()
      //
      //     console.log('enter')
      //
  		// 	},
      //
      //   beforeLeave() {
      //
      //     pageLeave = true
      //
      //   }
  		// }


  	],













    views: [
      {
        namespace: "home",

        beforeEnter() {

          // // Cite text
          // citeText()
          //
          // // Load videos on the page and lazyload
          // loadVideos()
          //
          // // Add images
          // addImages()
          // $("img").lazyload
          //
          // // Load carousels
          // loadCarousel()

          // Play video
          var vid = document.getElementById("landingVideo");
          vid.play();
          $('.fullVideo').css('opacity', '1')

          // Make each element tagged with move, movable
          $('.move').each(function(index) {
            $(this).draggable({ handle: ".heading" })
          })

          // Context Statement
          $('.contextStatement').click(function(){
            $('.statement').css('animation', 'popdown 0.5s forwards')
            $(this).css('opacity', '0')
            setTimeout(function() {
              $('.contextStatement').css('display', 'none')
            }, 500);
          })

          $('.contextStatementLink').click(function(){
            $('.statement').css('animation', 'popup 0.5s forwards')
            $('.contextStatement').css('display', 'grid')
            $('.contextStatement').css('opacity', '1')
          })

        },

        beforeLeave() {
          console.log('homepageLeave')
          $('.fullVideo').css('opacity', '0')
          var vid = document.getElementById("landingVideo");
          vid.pause();
        }

      },

      {
        namespace: "tapeface",

        beforeEnter() {

          $('#textArea').on('keypress', function(event){
            var regex = new RegExp("^[a-zA-Z*&@#!., ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
               event.preventDefault();
               return false;
            }
          })

          $('#textArea').on('input', function(){
            document.querySelector('#textAreaOverlay').innerHTML = document.querySelector('#textArea').innerHTML
            document.querySelector('#textAreaPadding').innerHTML = document.querySelector('#textArea').innerHTML
          })

        }

      },

      {
  			namespace: "sectionOne",

        beforeEnter() {

          // Cite text
          citeText()

          // Load videos on the page and lazyload
          loadVideos()

          // Load Tables properly
          loadTables()

          // Add images
          addImages()
          $("img").lazyload

        },

        afterEnter() {

          // Load in the section breakdown
          chaptersSectionBreakdown = document.querySelectorAll('.sectionBreakdownOne')
          pageText = document.querySelectorAll('.text')
          sectionBreakdownLoad(chaptersSectionBreakdown, pageText)
          $('.sectionBreakdownOneWrapper').css('display', 'block')
          $('.sectionBreakdownOneWrapper').css('animation', 'popup 0.4s forwards')

          // Load carousels
          loadCarousel()
          scrollToHash()

        },

        beforeLeave() {

          // Remove the section breakdown from the page
          $('.sectionBreakdownOneWrapper').css('animation', 'popdown 0.4s forwards')
          setTimeout(function() {
            $('.sectionBreakdownOneWrapper').css('display', 'none')
          }, 400);

        }

      },

  		{
  			namespace: "sectionTwo",

        beforeEnter() {

          // Cite text
          citeText()

          // Load videos on the page and lazyload
          loadVideos()

          // Load Tables properly
          loadTables()

          // Add images
          addImages()
          $("img").lazyload

        },

        afterEnter() {

          // Load in the section breakdown
          chaptersSectionBreakdown = document.querySelector('.sectionBreakdownTwo')
          pageText = document.querySelectorAll('.text')
          sectionBreakdownLoad(chaptersSectionBreakdown, pageText)
          $('.sectionBreakdownTwoWrapper').css('display', 'block')
          $('.sectionBreakdownTwoWrapper').css('animation', 'popup 0.4s forwards')

          // Load carousels
          loadCarousel()
          // Scroll to requested point
          scrollToHash()

        },

        beforeLeave() {

          // Remove the section breakdown from the page
          $('.sectionBreakdownTwoWrapper').css('animation', 'popdown 0.4s forwards')
          setTimeout(function() {
            $('.sectionBreakdownTwoWrapper').css('display', 'none')
          }, 400);

        }

      },

  		{
  			namespace: "sectionThree",

        beforeEnter() {

          // Cite text
          citeText()

          // Load videos on the page and lazyload
          loadVideos()

          // Load Tables properly
          loadTables()

          // Add images
          addImages()
          $("img").lazyload

        },

        afterEnter() {

          // Load in the section breakdown
          chaptersSectionBreakdown = document.querySelectorAll('.sectionBreakdownThree')
          pageText = document.querySelectorAll('.text')
          sectionBreakdownLoad(chaptersSectionBreakdown, pageText)
          $('.sectionBreakdownThreeWrapper').css('display', 'block')
          $('.sectionBreakdownThreeWrapper').css('animation', 'popup 0.4s forwards')

          // Load in the bits pane
          $('.bitsWrapper').css('display', 'block')
          $('.bitsWrapper').css('animation', 'popup 0.55s forwards')

          $('.placedStamps').css('display', 'block')

          // Load carousels
          loadCarousel()

          // Scroll to requested point
          scrollToHash()

        },

        beforeLeave() {

          // Remove the section breakdown from the page
          $('.sectionBreakdownThreeWrapper').css('animation', 'popdown 0.4s forwards')
          setTimeout(function() {
            $('.sectionBreakdownThreeWrapper').css('display', 'none')
          }, 400);

          // Remove the bits pane from the page
          $('.bitsWrapper').css('animation', 'popdown 0.55s forwards')
          setTimeout(function() {
            $('.bitsWrapper').css('display', 'none')
          }, 400);

          $('.placedStamps').css('display', 'none')

        }

      }
    ]
  })


  // Document variables set
  var pageText
  var pageLeave = false
  var chaptersSectionBreakdown


  // Make each element tagged with move, movable
  $('.move').each(function(index) {
    $(this).draggable({ handle: ".heading" })
  })


  // Info dropdown
  var infoSectionDown = false
  $('.info, .infoDropdown a').click(function() {
    if (infoSectionDown == false) {
      $('.infoDropdownWrapper').slideDown(100)
      $('header .left').css('border-radius', '10px 0px 0px 0px')
      $('header .right').css('border-radius', '0px 10px 0px 0px')
      infoSectionDown = true
    } else {
      $('.infoDropdownWrapper').slideUp(100)
      setTimeout(function() {
        $('header .left').css('border-radius', '10px 0px 0px 10px')
        $('header .right').css('border-radius', '0px 10px 10px 0px')
      }, 100);
      infoSectionDown = false
    }
  })


  // Allow section breakdown to be closed
  $('.sectionBreakdown .exit').click(function() {
    $('.sectionBreakdownWrapper').css('display', 'none')
  })
  $('.window .exit').click(function() {
    $(this).parents('.window').css('display', 'none')
  })
  $('.sectionBreakdownWrapper .exit').click(function() {
    $(this).parents('.window').css('display', 'none')
  })
  $('.bitsWrapper .exit').click(function() {
    $('.bitsWrapper').css('display', 'none')
  })


  // Add in numbers for citations
  function citeText() {
    var citeCounter = 1
    var references = document.querySelectorAll('.references p')

    $('.text cite').each(function(index) {
      $(this).html(citeCounter)
      $(references[index]).prepend(citeCounter + ".&ensp;")
      citeCounter = citeCounter + 1
    })
  }


  // Insert Images from database
  function addImages() {
    // Setup the bucket into an array
    var imageBucket = new Array

    // Go through each image in the bucket
    $('.imageBucket img').each(function(index) {
      // Grab the source file path and place it into an array
      imageBucket[index] = $(this).attr('src')
    })

    // Grab the image tags from the body copy
    $('.text img').each(function(index) {
      // Assign the src file path
      $(this).attr('src', imageBucket[index])
      if ($(this).hasClass('edge') == true) {
        $(this).wrap("<div class='photo edge'></div>")
        $(this).parent().after("<div class='photoEdgePadding'></div>")
      } else {
        $(this).wrap("<div class='photo'></div>")
      }
      // $(this).after("<div class='colour'></div>")
    })
  }

  // Load Tables properly
  // loadTables()
  function loadTables() {
    $(".table p").remove()
    $(".table").addClass('depth')
  }


  // Scroll to hash, if directing from home page
  function scrollToHash() {
    var target = window.location.hash;
    console.log(document.querySelector(target).offsetTop)
    // only try to scroll to offset if target has been set in location hash
    if (target != '') {
      var $target = jQuery(target);
      jQuery('html, body').stop().animate({'scrollTop': $target.offset().top - 100}, 900, 'swing');
    }
  }


  // Get rid of text orphans
  typeMate()
  function typeMate() {
    const typeMateInstance = new TypeMate();
    // Run it
    typeMateInstance.apply();
  }


  // Go home link
  $('.linkHome').click(function(event) {
    if (location.pathname == "/") {
      event.preventDefault();
      console.log('Home')
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  })


  // Scroll to top
  var arrowDown = true
  $('.topPage').click(function(event) {
    if (window.innerWidth > 600) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      $('.sectionBreakdown .overflow').slideToggle()
      if (arrowDown == true) {
        $(this).css('transform', 'rotate(180deg)')
        arrowDown = false
      } else {
        $(this).css('transform', 'rotate(0deg)')
        arrowDown = true
      }
    }
  })


  // BITS PANE STAMPS
  var stamp
  var cursorImage
  var cursorPosChange = 0
  var hovered = false
  var stampPlaced = false
  var bitIndex
  var bits = document.querySelectorAll('.bit')
  const cursor = document.querySelector(".stamp")
  const cursorSize = '12px'

  // Sets up click event for each bit
  $(".bit").on('click', selectStamp)

  // Prevent stamping while over bit pane
  $(".bit").hover(function() {
    hovered = true
  }, function() {
    hovered = false
  })

  // Select the stamp and copy the image to the cursor
  function selectStamp() {
    bitIndex = $('.bit').index(this)
    $(this).css('backgroundColor', '#2bb2f7')
    stamp = $(this).find('.hide').attr('src')
    placeStamp(stamp)
  }

  // Add a click event for when the bit is stamped
  function placeStamp(stamp) {
    $('.stamp').css('mix-blend-mode', 'multiply')
    cursorImage = $(cursor).find('img')
    $(cursorImage).attr('src', stamp)
    document.addEventListener('click', saveStamp)
  }

  // Create an image of the bit when stamped
  function saveStamp(event) {
    if (hovered == false) {
      $('.placedStamps').append("<img src='" + stamp + "' style='top: " + (event.pageY - 50) + "px; left: " + (event.pageX - 50) + "px;'>")
      bits[bitIndex].style.backgroundColor = 'white'
      stamp = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
      cursorImage = $(cursor).find('img')
      $(cursorImage).attr('src', stamp)
      document.removeEventListener('click', saveStamp)
      stampPlaced = true
    }
  }

  // Custom mouse cursor
  // Track the mouse and replace with the cursor DIV
  document.addEventListener("mousemove", function (event) {
    const pageX = event.pageX
    const pageY = event.pageY
    cursor.style.opacity = '1'

    // Place mouse image on mouse position
    cursor.style.left = pageX - 50 - cursorPosChange + "px"
    cursor.style.top = (pageY - window.pageYOffset) - 50 - cursorPosChange + "px"
  })


  // Load Videos on page and LazyLoad
  loadVideos()
  function loadVideos() {

    // Go through and add video source
    $('.video').each(function(index){
      var videoLink = $(this).html()
      // var videoThumbnail = $(this).find('img').src
      $(this).replaceWith("<div id='" + "video" + index + "' class='videoWrapper' data-yt-url='" + videoLink +"'><img async class='play-youtube-video'></div>")
    })

    // Youtube video lazy load
    $(document).ready(function() {
      // select all video wrappers on page
      var video_wrapper = $('.videoWrapper');
      // if a video wrapper exists then
      if(video_wrapper.length) {
        // on click inject the youtube link data from the HTML
        $('.videoWrapper').on('click', function(){
          $('#'+this.id).html('<iframe allowfullscreen frameborder="0" class="embed-responsive-item" src="' + $(this).data("yt-url") + '"></iframe>');
          // add margin top and video sizing rules
          $(this).addClass('iframe-container')
        });
      }
    })
  }

  // Load in Owl Carousel
  // loadCarousel()
  function loadCarousel() {
    $(document).ready(function(){

      $(".carousel br").remove()
      $(".carousel").attr('class', 'owl-carousel');
      $('.owl-carousel').after("<div class='carouselPadding'></div>")

      // GAMES CAROUSEL
      $('.owl-carousel').owlCarousel({
        loop: true,
        center: true,
        margin: 20,
        nav: false,
        dots: true,
        responsive:{
          0: {
            items:2
          },
          600: {
            items:2
          },
          1000: {
            items:2
          }
        }
      });

    });
  }

  // Load in the section breakdown
  function sectionBreakdownLoad(chaptersSectionBreakdown, pageText) {

    console.log('sectionBreakdownLoad')

    // Grab all chapter blocks
    var chapters = $(pageText).find('.chapter')
    var chapterWrappers = $(pageText).find('.chapterWrapper')
    var breakdownSections = $(chaptersSectionBreakdown).find('.sectionsWrapper')
    var partColour = $(pageText).find('.partColour').css( "background-color" )
    var middle = window.innerHeight / 2
    var chapterHeadings = document.querySelectorAll('.text .chapter .title')
    var currentChapterHeading = document.querySelector('.currentChapter')
    var currentChapter

    chaptersSectionBreakdown = $(chaptersSectionBreakdown).find('.chapter')
    // console.log(chaptersSectionBreakdown.length)
    // console.log(chapters.length)
    // console.log(breakdownSections)
    // console.log(chapterWrappers)

    // Create an array setting all closed
    var chapterWrapperOpen = []
    $(chapterWrappers).each(function(index) {
      chapterWrapperOpen[index] = false
    })

    // Check once
    checkOnScroll()

    // On page scrolling, colour the block in view
    window.addEventListener("scroll", checkOnScroll)

    function checkOnScroll() {

      // Remove previous event listener
      if (pageLeave == true) {
        window.removeEventListener("scroll", checkOnScroll)
        pageLeave = false
        console.log('removed event listener')
      } else {

        // Run a check for each chapter
        $(chapters).each(function(index) {
          // Do not run for the last placeholder chapter
          if (index < chapters.length - 1) {
            // Check if the window scroll is between this element or the next
            if (window.scrollY + middle > chapters[index].offsetTop && window.scrollY + middle < chapters[index + 1].offsetTop) {
              chaptersSectionBreakdown[index].style.backgroundColor = partColour
              // Replace the header text with current chapter name
              if (chapterHeadings[index - 1] == null) {
                currentChapterHeading.innerHTML = $(chaptersSectionBreakdown).find('.chapterHeading').html()
              } else {
                currentChapter = chapterHeadings[index - 1].innerHTML
                currentChapterHeading.innerHTML = currentChapter
              }
            // Check if at top of page, make first chapter highlighted
          } else if (window.scrollY + middle < chapters[0].offsetTop) {
              chaptersSectionBreakdown[0].style.backgroundColor = partColour
              chaptersSectionBreakdown[1].style.backgroundColor = 'white'
              currentChapterHeading.innerHTML = $(chaptersSectionBreakdown).find('.chapterHeading').html()
            // Reset all others to white
            } else {
              chaptersSectionBreakdown[index].style.backgroundColor = 'white'
              if ($(chaptersSectionBreakdown[index]).parents('.sectionsWrapper').length == 1) {
                $(chaptersSectionBreakdown[index]).css('backgroundColor', 'rgb(225, 225, 225)')
              }
            }
          }
        })

        // Folds up/down sections when they are in view
        $(chapterWrappers).each(function(index) {
          if (index == chapterWrappers.length - 1) {
            return
          } else {
            if (window.scrollY + middle > chapterWrappers[index].offsetTop && window.scrollY + middle < chapterWrappers[index + 1].offsetTop) {
              if (chapterWrapperOpen[index] == false) {
                $(breakdownSections[index]).slideDown(250, "swing")
                chapterWrapperOpen[index] = true
              }
            } else {
              if (chapterWrapperOpen[index] == true) {
                $(breakdownSections[index]).slideUp(250, "swing")
                chapterWrapperOpen[index] = false
              }
            }
          }
        })
      }
    }

    // Scroll to the right part of the page
    $(chaptersSectionBreakdown).each(function(index) {
      chaptersSectionBreakdown[index].addEventListener('click', function() {
        var scrollTo = chapters[index].offsetTop - 100
        window.scrollTo({
          top: scrollTo,
          left: 0,
          behavior: 'smooth'
        })
      })
    })

  }


  // function infiniteScroll() {
  //   // Infinte scrolling
  //   document.addEventListener('scroll', checkLoadNextPage)
  //
  //   var currentPage = document.querySelectorAll('.page')
  //   var sectionBreakdownWrappers = document.querySelectorAll('.sectionBreakdownWrapper')
  //   var pageOpen = []
  //   var pageBreakdownShow = [false, false, false]
  //   var pageCounter = 0
  //
  //   function checkLoadNextPage() {
  //     // Check for each page
  //     $(currentPage).each(function(index){
  //       // Run only if section is showing
  //       if (pageOpen[index] == true) {
  //         // Check if scroll is within range
  //         if (window.scrollY > currentPage[index].offsetTop - 800 && window.scrollY < currentPage[index].offsetTop + currentPage[index].offsetHeight - 800) {
  //           // If not showing yet, show the breakdown
  //           if (pageBreakdownShow[index] == false) {
  //             pageBreakdownShow[index] = true
  //             // Show the section breakdown
  //             setTimeout(function() {
  //               $(sectionBreakdownWrappers[index]).css('display', 'block')
  //               $(sectionBreakdownWrappers[index]).css('animation', 'popup 0.4s forwards')
  //               // Load in the section breakdown
  //               chaptersSectionBreakdown = $(sectionBreakdownWrappers[index]).find('.sectionBreakdown')
  //               pageText = $(currentPage[index]).find('.text')
  //               sectionBreakdownLoad(chaptersSectionBreakdown, pageText)
  //             }, 400);
  //           }
  //         } else {
  //           // If not in range, check that it is showing and remove
  //           if (pageBreakdownShow[index] == true) {
  //             pageBreakdownShow[index] = false
  //             // Animate out the section breakdown
  //             $(sectionBreakdownWrappers[index]).css('animation', 'popdown 0.4s forwards')
  //             setTimeout(function() {
  //               $(sectionBreakdownWrappers[index]).css('display', 'none')
  //             }, 400);
  //           }
  //         }
  //       }
  //     })
  //
  //     // When the user reaches the bottom of the page, do this
  //     if (window.scrollY + window.innerHeight > $('main').height() - 50 && pageCounter < currentPage.length) {
  //       // Display the next page of content
  //       $(currentPage[pageCounter]).removeClass('hide')
  //       $(currentPage[pageCounter]).css('display', 'block')
  //       // Let the checker know that the page is open
  //       pageOpen[pageCounter] = true
  //       pageCounter = pageCounter + 1
  //       console.log('Load Page' + "" + pageCounter)
  //     }
  //   }
  // }


  // Page Loading Video
  loadPage()
  function loadPage() {
    var pageLoaded = false
    var timeLoaded = false
    $(window).on("load", function() {
      pageLoaded = true
      if (pageLoaded == true && timeLoaded == true) {
        $('.loader').css('opacity', '0')
      }
    })
    setTimeout(function() {
      timeLoaded = true
      if (pageLoaded == true && timeLoaded == true) {
        $('.loader').css('opacity', '0')
      }
    }, 1000);
  }

})
